import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

export default function NewsPostList(props) {
    return (
        <div className="news-list">
            <table className="list-item-box">
                <colgroup>
                    <col width="80%" />
                    <col width="20%" />
                </colgroup>
                <tbody>
                    {props.data.map((Element) => (
                        <tr className="news-contents" key={Element.board_id}>
                            <td>
                                <div className="news_date">{Element.date}</div>
                                <Link to={`/news/${Element.board_id}`} className="noticeLink newsLink">
                                    <p>{Element.title}</p>
                                </Link>
                            </td>
                            {/* <td>
                                <p dangerouslySetInnerHTML={{ __html: Element.content }}></p>
                            </td> */}
                            <td>
                                <FaArrowRight />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/analytics_back.png";

import "../../assets/css/contents/product.css";
import AskSection from "../../componenets/main/askSection";

export default function Analytics() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="Analytics" sub={hide} subTitle="broanex" etc="Data Reporting & Analytics 솔루션" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            데이터를 기반으로 신속한 의사결정을 지원하는 리포팅 솔루션
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>
                            DF-Designing Analytics은 제조현장 내에 분산된 데이터를 누구나 쉽게 수집, 통합하여 <br />
                            이를 직관적으로 이해 할 수 있는 다양한 시각적 형태로 보여줌으로써 기업이 보유한 정보에 가치를
                            <br /> 더해주는 솔루션입니다.
                        </p>

                        <div className="background-analytics round-content-container">
                            <div className="round-content-box">
                                <div className="round-item">
                                    <div className="round-text">
                                        <div className="round-text-title">1. 손쉬운 Report 구성</div>
                                        <div className="round-text-bodyT">
                                            별도의 프로그래밍 없이 쉽게 데이터를 <br />
                                            구성 할 수 있습니다.
                                        </div>
                                    </div>
                                </div>
                                <div className="round-item">
                                    <div className="round-text">
                                        <div className="round-text-title">2. 사용자별 맞춤서비스</div>
                                        <div className="round-text-bodyT">
                                            사용자별로 필요한 데이터만 <br /> 조회할 수 있도록 조건을 설정할 수 <br />
                                            있습니다.
                                        </div>
                                    </div>
                                </div>
                                <div className="round-item">
                                    <div className="round-text">
                                        <div className="round-text-title">3. Data 확장성, 편리성 제공</div>
                                        <div className="round-text-bodyT">
                                            Report를 관리할 수 있으며,
                                            <br />
                                            쿼리 편집, 필터링 정의 등 데이터 분석에
                                            <br /> 유용한 기능을 제공합니다.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">시스템 구성도</h2>

                    <div className="service_cont_cover" style={{ paddingTop: 10 }}>
                        <img src="/img/Analytics_standard.png" alt="" style={{ width: "100%" }} />
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            실시간 데이터 분석을 통한 전략적 의사결정 지원 솔루션
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>
                            제조현장에서 발생하는 정보를 통합하여 실시간으로 분석하고 Reporting 함으로써
                            <br /> 전략적인 의사결정을 지원합니다.
                        </p>
                    </div>
                    <div className="product_coverList_cont">
                        <ul>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-design.png" alt="" />
                                </div>
                                <p>
                                    사용하기 쉬운
                                    <br /> Designer Tool
                                </p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-chart.png" alt="" />
                                </div>
                                <p>
                                    다양한 차트와
                                    <br />
                                    풍부한 시각적 효괴제공
                                </p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-data-center.png" alt="" />
                                </div>
                                <p>
                                    다양한 형태의
                                    <br />
                                    데이터소스 연결지원
                                </p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-software.png" alt="" />
                                </div>
                                <p>
                                    멀티 디바이스 지원
                                    <br /> (PC, Tablet)
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout background-blue-w">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>도입효과</h3>
                    </div>
                    <div className="product_effect_cont product_feature_cont">
                        <div className="product_feature_item">
                            <img src="/img/view-solition1.png" alt="" />
                        </div>
                        <div className="product_feature_item">
                            <ul>
                                <li>
                                    <div className="analtyics_effect_list">
                                        <h4>1. 마케팅의 기반이 되는 웹분석</h4>
                                        <p>
                                            분석결과를 신속하게 확인 할 수 있습니다. <br />
                                            DF-Designing Analytics은 실시간 웹 데이터를 수집하고 빠르게 분석결과를 얻어
                                            <br /> 빠르게 조치를 취할 수 있습니다.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="analtyics_effect_list">
                                        <h4>2. 다양한 마케팅 분석</h4>
                                        <p>
                                            수집한 데이터를 한 곳으로 통합하여 고객에 대한 <br />
                                            종합적인 관점을 바탕으로 실시간 이해도를 확보 할 수 있습니다.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="analtyics_effect_list">
                                        <h4>3. 효율적인 예측 분석</h4>
                                        <p>
                                            복잡한 작업을 수월하게 할 수 있습니다. <br />
                                            DF-Designing Analytics을 사용하여 복잡한 업무를 처리하고,
                                            <br /> 문제에 대한 해답을 찾을 수 있습니다.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="analtyics_effect_list">
                                        <h4>4. 강력한 기여도 분석</h4>
                                        <p>
                                            기여도 분석을 통해 전환율을 살펴보고 그에 따라 투자 결정을 할수 있으며,
                                            <br />더 나은 의사 결정을 내릴 수 있습니다.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3> 화면예시</h3>
                    </div>
                    <div className="analtyics_layout_cont">
                        <div className="analtyics_layout_item">
                            <div>
                                <img src="/img/analtyics1.png" alt="" />
                                {/* <p>편리한 Design Tool 제공</p> */}
                            </div>
                            <div>
                                <img src="/img/analtyics2.jpg" alt="" />
                                {/* <p>다양한 Chart, 맞춤형 Report 지원</p> */}
                            </div>
                        </div>
                        <div className="analtyics_layout_item">
                            <div>
                                <img src="/img/analtyics3.jpg" alt="" />
                                {/* <p>직관적인 데이터 탐색 (Drill Down)</p> */}
                            </div>
                            <div>
                                <img src="/img/analtyics4.png" alt="" style={{ height: 257 }} />
                                {/* <p>다양한 IT기기별 최적화 화면 제공</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  문의 하기 */}
            <AskSection />
        </>
    );
}

import React from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/makecase_back.png";
import itemCover from "../../assets/images/case_cover.png";
import { Link } from "react-router-dom";

const data = [
    {
        id: 1,
        img: itemCover,
        title: "구축 사례",
        text: "구축사례 소개페이지 입니다.",
    },
    {
        id: 2,
        img: itemCover,
        title: "구축 사례",
        text: "구축사례 소개페이지 입니다.",
    },
    {
        id: 3,
        img: itemCover,
        title: "구축 사례",
        text: "구축사례 소개페이지 입니다.",
    },
    {
        id: 4,
        img: itemCover,
        title: "구축 사례",
        text: "구축사례 소개페이지 입니다.",
    },
    {
        id: 5,
        img: itemCover,
        title: "구축 사례",
        text: "구축사례 소개페이지 입니다.",
    },
    {
        id: 6,
        img: itemCover,
        title: "구축 사례",
        text: "구축사례 소개페이지 입니다.",
    },
];

export default function MakeCase() {
    return (
        <>
            <PageHeaderBackground title="구축사례" subTitle="broanex" etc="" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="case_title">
                        <h3>주요기업</h3>
                        <p>풍부한 구축경험을 바탕으로 최적의 컨설팅 & 구축서비스를 제공합니다.</p>
                    </div>

                    <div className="case-item-box">
                        {data.map((list) => (
                            <>
                                <Link to={`/case/${Element.id}`} className="case-item">
                                    <div key={list.id} className="caseItem-cont">
                                        <div>{list.img ? <img src={list.img} alt="" /> : <div>커버없음</div>}</div>
                                        <h4>{list.title}</h4>
                                        <p>{list.text}</p>
                                    </div>
                                </Link>
                            </>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

import { useState, useEffect } from "react";
import "../../assets/css/layouts/header.css";
import { Link, useLocation } from "react-router-dom";
import DropDownMenu from "./dropDownMenu";

import styled from "styled-components";
import SideDropMenu from "./sideDropMenu";

const lnbList = [
    { id: 1, name: "솔루션" },
    { id: 2, name: "제품" },
    { id: 3, name: "홍보센터" },
    { id: 4, name: "회사소개" },
];

export default function Header(props) {
    const [dropDown, setDropDown] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const { pathname } = useLocation();

    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
    });

    // url이 바뀌면 메뉴 닫히게 처리
    useEffect(() => {
        setDropDown(false);
    }, [pathname]);

    return (
        <>
            <HeaderContainer className="header_container">
                <div className={dropDown ? "header-bg header-bg_hover" : "header-bg"}></div>
                <div className={scrollPosition ? "header_inner_cont header_change" : "header_inner_cont"}>
                    <div className="header_logo_cont">
                        <Link to="/">
                            <h1>
                                {dropDown || scrollPosition || props.headerChange ? (
                                    <img src="/img/broanex_login_logo_black.png" alt="" style={{ width: 180 }} />
                                ) : (
                                    <img src="/img/broanex_login_logo.png" alt="" style={{ width: 180 }} />
                                )}
                                <span className="hidden">브로넥스 홈페이지</span>
                            </h1>
                        </Link>
                    </div>
                    <div className="header_lnb_cont">
                        <ul className="header_menu_list">
                            {lnbList.map((list) => (
                                <li
                                    key={list.id}
                                    onMouseEnter={() => {
                                        setDropDown(true);
                                    }}
                                >
                                    <div
                                        style={{
                                            color: dropDown || scrollPosition || props.headerChange ? "#1C2126" : "",
                                        }}
                                    >
                                        {list.name}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        className="header-menu-toggle"
                        onClick={() => {
                            setMenuToggle(true);
                        }}
                    >
                        {scrollPosition ? <img src="/img/hamburger-black.png" alt="" style={{ width: 32 }} /> : <img src="/img/hamburger-white.png" alt="" style={{ width: 32 }} />}
                    </div>
                </div>
                <DropDownMenu show={dropDown} hide={() => setDropDown(false)} />
                <SideDropMenu
                    show={menuToggle}
                    hide={() => {
                        setMenuToggle(false);
                    }}
                />
            </HeaderContainer>
        </>
    );
}

const HeaderContainer = styled.div`
    .header-menu-toggle {
        display: none;
    }

    @media screen and (max-width: 767px) {
        .header_inner_cont {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        .header_lnb_cont {
            display: none;
        }

        .header-menu-toggle {
            display: block;
            /* padding: 0 20px 0 0; */
        }
    }
`;

import React, { useEffect } from "react";
import styled from "styled-components";

export default function KaKaoAsk() {
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        try {
            if (window.Kakao) {
                const kakao = window.Kakao;
                if (!kakao.isInitialized()) {
                    kakao.init("b50396693e5f68ce3a5750f04e8df454");
                }
            }

            window.Kakao.Channel.createChatButton({
                container: "#kakao-talk-channel-chat-button",
                channelPublicId: "_xkwgNxb",
                title: "consult",
                size: "small",
                color: "yellow",
                shape: "pc",
                supportMultipleDensities: true,
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
        } catch (err) {}
    }, []);

    return (
        <>
            <KakaoFixBtn>
                <div id="kakao-talk-channel-chat-button"></div>
            </KakaoFixBtn>
        </>
    );
}

const KakaoFixBtn = styled.div`
    position: fixed;
    bottom: 15px;
    right: 24px;
`;

import React, { useEffect, useState } from "react";
import Axios from "axios";
// import PageHeaderTitle from "../../componenets/main/pageTitle";
import "../../assets/css/promotion/notice.css";
import Pagination from "../../componenets/post/pagination";
import NewsPostList from "../../componenets/post/newsPostList";
import background from "../../assets/images/news-background.png";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";

export default function News() {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(5);
    const [hide, setHide] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            Axios.get("http://211.214.247.21:8000/news/getNewsList").then((response) => {
                setPosts(response.data.reverse());
            });
        };
        fetchData();
    }, []);

    const indexOfLast = currentPage * postsPerPage;
    const indexOfFirst = indexOfLast - postsPerPage;
    const currentPosts = (posts) => {
        let currentPosts = 0;
        currentPosts = posts.slice(indexOfFirst, indexOfLast);
        return currentPosts;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <PageHeaderBackground title="언론보도" sub={hide} subTitle="broanex" etc="브로넥스에서 전하는 새로운 소식을 확인해보세요." background={background} />
            {/* 게시물 */}
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <NewsPostList data={currentPosts(posts)} />
                    <div className="page_item">
                        <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} currentPage={currentPage} onPageChange={handlePageChange} />
                    </div>
                </div>
            </section>
        </>
    );
}

import React, { useState, useRef } from "react";
import PageHeaderTitle from "../../componenets/main/pageTitle";

import "../../assets/css/contents/ask.css";
import Modal from "../../componenets/main/modal";
import emailjs from "@emailjs/browser";

export default function AskPage() {
    const [modalOpen, setModalOpen] = useState(false);
    const [IsChecked, setIsChecked] = useState(false);
    const [account, setAccount] = useState({
        companyNm: "",
        userNm: "",
        phone: "",
        position: "",
        email: "",
        askContent: "",
        consentChk: "",
    });

    const onChangeAccount = (e) => {
        setAccount({
            ...account,
            [e.target.name]: e.target.value,
        });
        console.log(e.target.value);
    };

    const CheckHandler = (checked) => {
        if (checked === true) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    };

    const form = useRef();
    const onSubmit = (e) => {
        e.preventDefault();

        if (IsChecked === true) {
            emailjs.sendForm("service_kk84ez7", "template_c2n37vs", form.current, "-U1BrsNky3lZ79D4_").then(
                (res) => {
                    alert("메일이 전송되었습니다.");
                    console.log(res);
                },
                (err) => {
                    alert("메일 전송에 실패하였습니다. 다시 시도해주세요.");
                    console.log(err);
                }
            );
        } else {
            alert("개인정보 제공에 동의해주세요.");
        }
    };

    return (
        <>
            <PageHeaderTitle title="문의하기" subTitle="broanex" etc="회사나 프로그램 구축에 대해 궁금한 점이 있다면 문의글을 남겨주세요." />
            <div className="background-gray">
                <div className="contents_inner_container_layout ask-contents-width">
                    <div className="ask-message-box">
                        <div className="ask-cover">
                            <img src="img/message.png" alt="메세지 아이콘" />
                        </div>

                        <div>
                            <p>정확한 상담을 위해 아래 항목을 모두 입력해주세요. 담당자가 연락처 또는 이메일로 답변해드립니다.</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <form onSubmit={onSubmit} ref={form}>
                        <div className="ask-form-content">
                            <div className="ask-form-item">
                                <label>문의분야</label>
                                <select className="form-control" name="consentChk" onChange={onChangeAccount} defaultValue={account}>
                                    <option value="0">선택해주세요.</option>
                                    <option value="1">MES</option>
                                    <option value="2">Analytics</option>
                                    <option value="3">QMS</option>
                                    <option value="4">MC</option>
                                    <option value="5">MB</option>
                                    <option value="6">ACS솔루션</option>
                                    <option value="7">ACS시뮬레이션</option>
                                </select>
                            </div>
                            <div className="ask-form-item">
                                <label>회사명</label>
                                <input type="text" id="companyNm" name="companyNm" onChange={onChangeAccount} className="form-control" />
                            </div>
                            <div className="ask-form-item">
                                <label>이름</label>
                                <input type="text" id="userNm" name="userNm" onChange={onChangeAccount} className="form-control" />
                            </div>
                            <div className="ask-form-item">
                                <label>연락처</label>
                                <input type="tel" id="phone" name="phone" onChange={onChangeAccount} className="form-control" />
                            </div>
                            <div className="ask-form-item">
                                <label>직급</label>
                                <input type="text" id="position" name="position" onChange={onChangeAccount} className="form-control" />
                            </div>
                            <div className="ask-form-item">
                                <label>이메일 주소</label>
                                <input type="email" id="email" name="email" onChange={onChangeAccount} className="form-control" />
                            </div>
                            <div className="ask-form-item">
                                <label>내용</label>
                                <textarea id="askContent" name="askContent" onChange={onChangeAccount} className="form-control text-control" />
                            </div>
                        </div>
                        <div className="confirm-ck">
                            <label>
                                <input type="checkbox" value={IsChecked} onChange={(e) => CheckHandler(e.target.checked)} />
                                고객 문의에 대한 답변 제공 및 현황관리를 위해&nbsp;
                                <span className="c-link" onClick={() => setModalOpen(true)}>
                                    개인정보의 이용에 동의
                                </span>
                                합니다.
                            </label>
                        </div>
                        <div className="askSubmit-box">
                            <button type="submit" className="askBtn">
                                문의하기
                            </button>
                        </div>
                    </form>
                </div>
            </section>

            {modalOpen && (
                <Modal
                    open={modalOpen}
                    close={() => setModalOpen(false)}
                    header="개인정보 이용동의"
                    main={
                        <p>
                            브로넥스는 문의하기 서비스 제공과 관련하여 아래와 같이 <br />
                            개인정보를 수집 및 이용하고 있습니다. <br />
                            <br />
                            수집되는 정보는 해당 목적 이외의 용도로는 이용되지 않습니다.
                            <br />
                            <br />
                            1. 개인정보 수집 목적
                            <br />
                            - 이용자의 신원 확인, 문의사항 확인, 처리결과 통보
                            <br />
                            <br />
                            2. 개인정보 수집 항목 <br />
                            - 이름, 회사명, 직급, 연락처, 이메일
                            <br />
                            <br />
                            3. 개인정보 이용 및 보유기간 <br />
                            개인정보의 수집 및 이용 목적이 달성되거나 이용자의 요청이 있는 경우에는
                            <br />
                            개인정보를 지체 없이파기합니다.
                            <br />
                            <br />
                            <br />
                            단, 다음의 개인정보 이용 및 보유기간은 아래와 같습니다. <br /> <br />
                            - 분쟁 처리와 관련된정보는 이용자 동의일로부터 3년 <br />- 관계 법령 위반에 따른 수사·조사등이 진행중인 경우에는 해당수사·조사 종료 시까지
                        </p>
                    }
                />
            )}
        </>
    );
}

import React from "react";
import PageHeaderTitle from "../../componenets/main/pageTitle";

export default function KeepInfo() {
    return (
        <>
            <PageHeaderTitle title="유지보수" subTitle="broanex" etc="프로그램의 안정적인 서비스를 위해 유지보수를 지원합니다." />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="keep_title">
                        <h3>유지보수 절차 안내</h3>
                    </div>
                    <div className="keep_cont">
                        <div className="keep_item">
                            <div className="keepItem_title">
                                <h4>오류사항 일 경우</h4>
                            </div>
                            <div>
                                <img src="/img/keep_info1.png" />
                            </div>
                        </div>
                        <div className="keep_item">
                            <div className="keepItem_title">
                                <h4>추가개발 일 경우</h4>
                            </div>
                            <div>
                                <img src="/img/keep_info2.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/guide_back.png";
import { FaArrowRight } from "react-icons/fa";

export default function Guide() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="브로넥스 서비스 가이드" sub={() => setHide(true)} subTitle="broanex" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="guide_title">
                        <h3>서비스 이용안내</h3>
                    </div>
                    <div className="guide_cont">
                        <div className="guide_item">
                            <div className="guideItem_title">
                                <h4>가이드 문서</h4>
                                <img src="/img/guide_icon.png" alt="문서" />
                            </div>
                            <div className="guide_text">
                                <p>제공하는 서비스의 이용 가이드 문서를 PDF 로 제공합니다.</p>
                            </div>
                            <div className="moveBtn">
                                <span>다운로드 </span>
                                <FaArrowRight />
                            </div>
                        </div>
                        <div className="guide_item">
                            <div className="guideItem_title">
                                <h4>Onkeeper 교육센터</h4>
                                <img src="/img/broanex_icon.png" alt="브로넥스" />
                            </div>
                            <div className="guide_text">
                                <p>사용자와 관리자를 위한 이용 방법 콘텐츠를 제공합니다.</p>
                            </div>
                            <div className="moveBtn">
                                <span>바로가기 </span>
                                <FaArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import React from "react";
import { useRoutes } from "react-router-dom";

import Home from "../views/home/home";
import Notice from "../views/promotion/notice";
import NoticePost from "../views/promotion/noticePost";
import AskPage from "../views/company/ask";
import Map from "../views/company/map";
import CompanyInfo from "../views/company/companyInfo";
import CompanyHistory from "../views/company/companyHistory";
import News from "../views/promotion/news";
import NewsPost from "../views/promotion/newsPost";
import MakeCase from "../views/company/makeCase";
import MakeCasePost from "../views/company/makeCasePost";
import KeepInfo from "../views/company/Keep_info";
import Guide from "../views/company/guide";
import Mes from "../views/product/mes";
import Suze from "../views/solution/suez";
import AskPop from "../componenets/main/askPop";
import Analytics from "../views/product/Analytics";
import Qms from "../views/product/QMS";
import McPage from "../views/product/Mc";
import MbPage from "../views/product/Mb";
import AcsSolution from "../views/product/AcsSolution";
import AcsSimulation from "./../views/product/AcsSimulation";
import DfDesigning from "../views/solution/DfDesigning";
import CompanyGreeting from "../views/company/company_greeting";
// import NotFoundPage from "../views/NotFound";

export default function Router() {
    return useRoutes([
        { path: "/", element: <Home /> },
        // { path: "/*", element: <NotFoundPage /> },
        { path: "/notice", element: <Notice /> },
        { path: "/notice/:board_id", element: <NoticePost /> },
        { path: "/news", element: <News /> },
        { path: "/news/:board_id", element: <NewsPost /> },
        { path: "/ask", element: <AskPage /> },
        { path: "/address", element: <Map /> },
        { path: "/about", element: <CompanyInfo /> },
        { path: "/history", element: <CompanyHistory /> },
        { path: "/case", element: <MakeCase /> },
        { path: "/case/:board_id", element: <MakeCasePost /> },
        { path: "/keep", element: <KeepInfo /> },
        { path: "/guide", element: <Guide /> },
        { path: "/askPop", element: <AskPop /> },
        { path: "/mes", element: <Mes /> },
        { path: "/suez", element: <Suze /> },
        { path: "/analytics", element: <Analytics /> },
        { path: "/qms", element: <Qms /> },
        { path: "/mc", element: <McPage /> },
        { path: "/mb", element: <MbPage /> },
        { path: "/acs-solution", element: <AcsSolution /> },
        { path: "/acs-simulation", element: <AcsSimulation /> },
        { path: "/DF-Designing", element: <DfDesigning /> },
        { path: "/company", element: <CompanyGreeting /> },
    ]);
}

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function SideDropMenu(props) {
    const { show, hide } = props;
    return (
        <>
            <SideMenu show={show}>
                <div>
                    <button onClick={hide} className="sideMenuClose_btn">
                        <img src="/img/icon-close.png" alt="" />
                    </button>
                </div>
                <div className="side-box">
                    <DropMenuList show={show}>
                        <div className="side_item">
                            <div className="sideMenuTopTitle">
                                <span>솔루션</span>
                            </div>
                            <ul>
                                <li className="submenuTitle">
                                    <h3>Define Factory</h3>
                                </li>
                                <li>
                                    <Link to="/DF-Designing" className="menu_link_text">
                                        DF-Designing
                                    </Link>
                                </li>
                                <li className="submenuTitle" style={{ marginTop: 32 }}>
                                    <h3>Fulfillment</h3>
                                </li>
                                <li>
                                    <Link to="/suez" className="menu_link_text">
                                        Suez
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="side_item">
                            <div className="sideMenuTopTitle">
                                <span>제품</span>
                            </div>
                            <ul>
                                <li className="submenuTitle">
                                    <h3>DF-Designing</h3>
                                </li>
                                <li>
                                    <Link to="/mes" className="menu_link_text">
                                        MES
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/analytics" className="menu_link_text">
                                        Analytics
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/qms" className="menu_link_text">
                                        QMS
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mc" className="menu_link_text">
                                        MC
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mb" className="menu_link_text">
                                        MB
                                    </Link>
                                </li>
                                <li className="submenuTitle" style={{ marginTop: 32 }}>
                                    <h3>Suez</h3>
                                </li>
                                <li>
                                    <Link to="/acs-solution" className="menu_link_text">
                                        ACS 솔루션
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/acs-simulation" className="menu_link_text">
                                        ACS 시뮬레이션
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="side_item">
                            <div className="sideMenuTopTitle">
                                <span>홍보센터</span>
                            </div>
                            <ul>
                                <li>
                                    <Link to="/notice" className="menu_link_text">
                                        공지사항
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/news" className="menu_link_text">
                                        언론보도
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="side_item">
                            {/* <div className="sideMenuTopTitle">
                                <span>회사소개</span>
                            </div> */}
                            <ul>
                                <li>
                                    <Link to="/about" className="menu_link_text">
                                        회사소개
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/history" className="menu_link_text">
                                        회사연혁
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/address" className="menu_link_text">
                                        오시는길
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </DropMenuList>
                </div>
            </SideMenu>
        </>
    );
}

const SideMenu = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        position: fixed;
        top: 0;
        border-top: 1px solid #ececec;
        box-sizing: border-box;
        padding: 20px;
        pointer-events: ${(props) => (props.show ? "auto" : "none")};
        opacity: ${(props) => (props.show ? "1" : "0")};
        transition: ${(props) => (props.show ? "all 0.4s" : "")};
        transition: ${(props) => (props.hide ? "" : "all 0.4s")};

        .sideMenuClose_btn {
            background-color: transparent;
            outline: none;
            border: none;
            margin-bottom: 40px;

            img {
                width: 32px;
            }
        }

        .side-box {
            display: block;
            margin-left: 20px;
        }
    }
`;

const DropMenuList = styled.div`
    width: 90%;

    .side_item {
        margin-bottom: 40px;
    }

    .sideMenuTopTitle {
        margin-bottom: 24px;
        font: normal normal bold 1.3rem/16px Pretendard;
        color: #1c2126;
    }

    li {
        padding-left: 28px;
        margin-bottom: 12px;
        text-align: left;
        font: normal normal bold 1rem/16px Pretendard;
        letter-spacing: 0px;
        color: #3e54d3;

        h3 {
            font-weight: 600;
        }

        a {
            font: normal normal 500 1rem/16px Pretendard;
        }
    }
`;

import React from "react";
import background from "../../assets/images/makecase_back.png";
import itemCover from "../../assets/images/case_cover.png";
import PageHeaderTitle from "../../componenets/main/pageTitle";
import { useNavigate, useParams } from "react-router-dom";

export default function MakeCasePost() {
    const navigate = useNavigate();

    return (
        <>
            {/* <PageHeaderTitle title="구축사례" subTitle="broanex" etc="" background={background} /> */}
            <section className="contents_container_layout">
                <div className="custUseCase_title">
                    <div className="contents_inner_container_layout">
                        <div className="custUseCase_title_sub">도입사례</div>
                        <h3>금형개발이력부터 양산금헝까지 통합관리 - 브로넥스</h3>
                    </div>
                </div>
                <div className="contents_inner_container_layout">
                    <div className="useText_cont">
                        <div className="useCase_text">
                            <h4>before</h4>
                            <p>구축사례</p>
                        </div>
                        <div className="useCase_text">
                            <h4>after</h4>
                            <p>구축사례</p>
                        </div>
                        <div className="custInfo-box">
                            <div className="caseUse_cover">
                                <img src={itemCover} alt="" />
                            </div>
                            <div className="custInfo-item">
                                <table>
                                    <colgroup>
                                        <col width="30%" />
                                        <col width="70%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="caseTable_title">업체명</td>
                                            <td className="caseTable_text">브로넥스</td>
                                        </tr>
                                        <tr>
                                            <td className="caseTable_title">대표이사</td>
                                            <td className="caseTable_text">오영탁</td>
                                        </tr>
                                        <tr>
                                            <td className="caseTable_title">사업분야</td>
                                            <td className="caseTable_text">정보통신, 소프트웨어 개발</td>
                                        </tr>
                                        <tr>
                                            <td className="caseTable_title">소재지</td>
                                            <td className="caseTable_text">경기도 수원시 영통로 에이스 하이엔드 타워 5층 510호, 511호</td>
                                        </tr>
                                        <tr>
                                            <td className="caseTable_title">홈페이지</td>
                                            <td className="caseTable_text">www.broanex.co.kr</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <button
                            className="listBack-btn"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            목록보기
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}

import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/mc_back.png";
import mc1 from "../../assets/images/mc1.jpg";
import mc2 from "../../assets/images/mc2.jpg";
import mc3 from "../../assets/images/mc3.jpg";

import "../../assets/css/contents/product.css";
import AskSection from "../../componenets/main/askSection";

export default function McPage() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="MC" sub={hide} subTitle="DF-Designing" etc="설비지능화 솔루션" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            스마트 공장의 양방향 설비 온라인을 실현하세요.
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>
                            생산설비의 다양한 프로토콜 지원으로 데이터의 수집 및 제어를 통해 <br />
                            유연하고 안정적인 양방향 설비온라인을 실현합니다.
                        </p>
                    </div>
                    <div className="background-analytics round-content-container">
                        <div className="round-content-box">
                            <div className="round-item">
                                <div className="round-text">
                                    <div className="round-text-title">1. 공장 자동화</div>
                                    <div className="round-text-bodyT">
                                        설비로부터 발생하는 대량의 데이터 수집 및 처리를 통한 효율적인 데이터 제공이 <br /> 가능합니다.
                                    </div>
                                </div>
                            </div>
                            <div className="round-item">
                                <div className="round-text">
                                    <div className="round-text-title">
                                        2. IoT 패러다임에 <br />
                                        효과적인 대응 필요
                                    </div>
                                    <div className="round-text-bodyT">다각적인 Connectivity 제공을 통한 설비 데이터의 안정적인 수집이 가능합니다.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            통합플랫폼 기반 혁신적인 제조지능화 서비스를 제공합니다.
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>DF-Designing의 MC만의 최적을 솔루션을 경험해보세요.</p>
                    </div>
                    <ul className="mc-effect_wrap">
                        <li className="mc-effect_cont">
                            <div className="mc-effect_item_cover">
                                <p style={{ backgroundImage: `url(${mc1})` }}>
                                    <img src="/img/mc1.jpg" alt="MC solution" style={{ display: "none" }} />
                                </p>
                            </div>
                            <div className="mc-effect_item_text">
                                <h4>Full-Automation 구축에 의한 무인 자동화 실현</h4>
                                <p>
                                    컨설팅, 상세 설계, 구축, 설비 및 솔루션 공급, 유지보수까지 스마트 팩토리 구축에 대한
                                    <br />
                                    모든 서비스를 제공합니다
                                </p>
                            </div>
                        </li>
                        <li className="mc-effect_cont">
                            <div className="mc-effect_item_cover">
                                <p style={{ backgroundImage: `url(${mc2})` }}>
                                    <img src="/img/mc2.jpg" alt="MC solution" style={{ display: "none" }} />
                                </p>
                            </div>
                            <div className="mc-effect_item_text">
                                <h4>실시간 데이터 수집 및 최적의 알고리즘 제공</h4>
                                <p>
                                    생산, 유틸리티, 에너지 설비의 데이터를 실시간으로 수집하고, 통합 데이터 활용 및 <br />
                                    분석 알고리즘을 제공하여 공정 개선 및 설비 효율을 극대화합니다.
                                </p>
                            </div>
                        </li>
                        <li className="mc-effect_cont">
                            <div className="mc-effect_item_cover">
                                <p style={{ backgroundImage: `url(${mc3})` }}>
                                    <img src="/img/mc3.jpg" alt="MC solution" />
                                </p>
                            </div>
                            <div className="mc-effect_item_text">
                                <h4>실시간 모니터링 및 현장 원격제어</h4>
                                <p>
                                    표준화된 설비 장애 분석 관리를 통해 작업시간 및 자원을 절감하며
                                    <br />
                                    서버, 설비, MC 실시간 모니터링으로 사전 장애 감지 및 대응을 <br /> 시행하여 설비가동률을 향상시킵니다.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="contents_container_layout background-reason">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title text_w">
                        <h3>DF-Designing MC를 선택하는 이유</h3>
                    </div>
                    <div className="product_coverList_cont product_effect_cont text_w">
                        <ul>
                            <li>
                                <div className="product_effect_list reason_h4">
                                    <h4>생산성</h4>
                                    <p style={{ fontSize: 62, fontWeight: 700, marginBottom: 24 }}>19%</p>
                                    <p>
                                        생산 설비 자동화 구축 및 제조관리시스템 기반의
                                        <br />
                                        현장재고/생산지시/실적관리를 통한 생산성 향상
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_list reason_h4">
                                    <h4>신속성</h4>
                                    <p style={{ fontSize: 62, fontWeight: 700, marginBottom: 24 }}>23%</p>
                                    <p>
                                        생산 설비 및 유틸리티 설비의
                                        <br />
                                        Digital Transformation을 통해 이상 징후 조기 발견 및<br /> AI를 통한 예측을 통한 신속성 대폭 향상
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_list reason_h4">
                                    <h4>품질</h4>
                                    <p style={{ fontSize: 62, fontWeight: 700, marginBottom: 24 }}>24%</p>
                                    <p>
                                        GMP/CCP 등 제품 품질의 주요 인자를
                                        <br />
                                        모니터링하고 AI기반 데이터 예측,
                                        <br /> 비전 설비 등을 통해 품질 불량 사전 예측
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/*  문의 하기 */}
            <AskSection />
        </>
    );
}

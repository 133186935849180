import React, { useState } from "react";
// import PageHeaderBackground from "./../../componenets/main/pageTitleBackground";

// import background from "../../assets/images/DF-Designing_back.png";

import "../../assets/css/contents/itemContent.css";
import AskSection from "../../componenets/main/askSection";

export default function DfDesigning() {
    // const [hide, setHide] = useState(false);
    const [tab, setTab] = useState(1);
    return (
        <>
            {/* <PageHeaderBackground sub={hide} title="DF-Designing" subTitle="solution" etc="노코드 플랫 기반의 생산실행관리 시스템" background={background} /> */}
            <section className="contents_container_layout background-dfdMainCont">
                <div className="contents_inner_container_layout df_intro_wrap">
                    <div className="df-intro_container">
                        <h3>
                            제조기업의 가혹한 공장환경 안에서도, COVID19 속에서도,
                            <br /> 탄력근무시간 및 비즈니스 운영난의 해결 키워드는 무엇일까요?
                        </h3>
                        <p>
                            브로넥스의 데이터 서비스 플랫폼 <strong>'DFD(Define Factory Designing) FACTORY'</strong> 을 통하여
                            <br /> 제조 기업의 TCO 절감 및 혁신적 디지털 전환에 대한 해답을 드리고자 합니다.
                        </p>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout ">
                    <div className="df_type_header">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            Define Factory Designing FACTORY 서비스
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                    </div>

                    <div className="df_service_type_cont">
                        <div className="df_service_text">
                            <p>
                                현장운용기술(OT)과 정보통신기술(ICT) 간 융합을 위해 수준 진단을 통한 실질적 도입 전략을 수립하고, 구체적인 추진 과제를 도출
                                <br />
                                개인화/맞춤화된 고객수요를 기술적으로 대응이 가능하게 하며, 현업이 당면한 과제를 해결하기 위한 추진방향으로
                            </p>
                        </div>
                        <ul>
                            <li>
                                <div className="df_serviceItem_text">
                                    <p>▼ 설비의 자동화</p>
                                </div>
                            </li>
                            <li>
                                <div className="df_serviceItem_text">
                                    <p>▼ 업무의 디지털화</p>
                                </div>
                            </li>
                            <li>
                                <div className="df_serviceItem_text">
                                    <p>▼ 제조데이터의 연결화</p>
                                </div>
                            </li>
                            <li>
                                <div className="df_serviceItem_text">
                                    <p>▼ 공장의 지능화 방안을 제시</p>
                                </div>
                            </li>
                        </ul>
                        <div className="service_cont_cover">
                            <img src="/img/smartFactory_service.jpg" alt="" />
                        </div>
                        <div className="service_cont_cover">
                            <img src="/img/smartFactory_made.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout ">
                    <h2 className="home-flex-title">Define Factory Designing 구성</h2>
                    <div className="service_cont_cover">
                        <img src="/img/DFD_platform.jpg" alt="" />
                    </div>

                    <div className="df_case_cont_tab">
                        <ul>
                            <li onClick={() => setTab(1)} className={tab ? "tabActive" : ""}>
                                <h4>CASE 1</h4>
                                <div>
                                    <span>Manual 업무의 Smart화</span>
                                </div>
                            </li>
                            <li>
                                <h4>CASE 2</h4>
                                <div>
                                    <span>단위 업무 問 연계化</span>
                                </div>
                            </li>
                            <li>
                                <h4>CASE 3</h4>
                                <div>
                                    <span>장비/설비 및 제어SW I/F 통합化</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {tab === 1 && (
                        <div className="df_case_cont">
                            <div className="df_case_item_cover">
                                <img src="/img/dfd_pointMap.jpg" alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout ">
                    <div className="df_feature_header">
                        <h4></h4>
                        <h3>DF-Designing을 활용하여 비즈니스 경쟁력을 확보하세요.</h3>
                    </div>
                    <div className="df_point_cont">
                        <div className="df_point_row">
                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint1.png" alt=" " />
                                </div>
                                <div>
                                    <h4>쉽고 빠르게 개발</h4>
                                </div>
                                <div>
                                    <p>
                                        엑셀을 이용한 화면 구성과
                                        <br /> 자동 HTML5 변환으로 빠른 개발이
                                        <br /> 가능합니다.
                                    </p>
                                </div>
                            </div>
                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint2.png" alt=" " />
                                </div>
                                <div>
                                    <div>
                                        <h4>코딩없이 개발</h4>
                                    </div>
                                    <div>
                                        <p>
                                            코딩없이 Business logic 구현이
                                            <br /> 가능하며 SQL 작성없이
                                            <br /> DataBase CRUD 구현이 가능합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint3.png" alt=" " />
                                </div>
                                <div>
                                    <div>
                                        <h4>생산성 증가</h4>
                                    </div>
                                    <div>
                                        <p>
                                            직관적이고 쉬운 개발환경을 활용한
                                            <br />
                                            자동화로 업무시간을 단축합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint4.png" alt=" " />
                                </div>
                                <div>
                                    <div>
                                        <h4>쉬운 유지보수</h4>
                                    </div>
                                    <div>
                                        <p>
                                            현업 담당자가 직접 화면(UI)개발 및<br />
                                            유지보수가 가능합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="df_point_row">
                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint5.png" alt=" " />
                                </div>
                                <div>
                                    <h4>개발 기간 단축</h4>
                                </div>
                                <div>
                                    <p>
                                        단순 반복 업무를 로봇이 대신 처리하며
                                        <br />
                                        표준화로 오류발생을 최소화 했습니다.
                                    </p>
                                </div>
                            </div>
                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint6.png" alt=" " />
                                </div>
                                <div>
                                    <div>
                                        <h4>Agile 방법론 실현</h4>
                                    </div>
                                    <div>
                                        <p>
                                            PaliPali(Agile) 방법론을 활용하여
                                            <br />
                                            고객의 피드백을 민첩하게 처리 가능하며
                                            <br /> 핵심 업무에만 집중하게 도와드립니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint7.png" alt=" " />
                                </div>
                                <div>
                                    <div>
                                        <h4>DEV/OPS 환경</h4>
                                    </div>
                                    <div>
                                        <p>
                                            쉬운 사용법으로 빌드와 배포가
                                            <br /> 자동화되어 개발과 운영 영역의
                                            <br /> 경계가 없습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="df_point_item">
                                <div className="df_point_item_cover">
                                    <img src="/img/dfdPoint8.png" alt=" " />
                                </div>
                                <div>
                                    <div>
                                        <h4>확장성 증대</h4>
                                    </div>
                                    <div>
                                        <p>
                                            기 업무와 유사한 UI를 제공하여 <br />
                                            시스템 활용도 증가시킵니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AskSection />
        </>
    );
}

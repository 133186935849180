import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/company_back.png";
import "../../assets/css/contents/company.css";

export default function CompanyGreeting() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="인사말" sub={hide} subTitle="broanex" etc="" background={background} />
            <section className="contents_container_layout background_company">
                <div className="contents_inner_container_layout">
                    <div className="greeting_box">
                        {/* <div className="greeting-title">
                            <h3>인사말</h3>
                        </div> */}
                        <div className="greeting-cont">
                            <div className="greeting-text">
                                <h4>변함없는 믿음과 사랑으로 함께 해 주시는 여러분께 감사의 인사를 전합니다.</h4>
                                <div>
                                    <div className="greetingText_item">
                                        <div className="item_strong">브로넥스는</div>
                                        <div className="item_text">
                                            지능형 솔루션 소프트웨어 개발 회사로, 현대 사회의 빠른 변화와 더불어 다양하고 복잡한 문제들에 대해 지능적이고 창의적인 솔루션을
                                            제공하는데 주력하고 있습니다.
                                        </div>
                                    </div>
                                    <div className="greetingText_item">
                                        <div className="item_strong">브로넥스의</div>
                                        <div className="item_text">
                                            소프트웨어는 최신 기술과 인공지능, 사물인터넷 등을 접목하여 고객들의 비즈니스를 효율적으로 지원하고, 미래 지능형 비즈니스에 대한
                                            선도적인 역할을 하고자 합니다.
                                        </div>
                                    </div>
                                    <div className="greetingText_item">
                                        <div className="item_strong">브로넥스의</div>
                                        <div className="item_text">비전은 지능형 솔루션 서비스 시장의 혁신을 이끄는 리더가 되는 것입니다.</div>
                                    </div>
                                </div>
                                <p style={{ marginBottom: 100 }}>
                                    <h5> 도전을 멈추지 않겠습니다. </h5>
                                    지능형 솔루션 사업의 리더로서 고객과 시장의 요구에 발맞춰 기존 사업을 고도화하며, 새로운 기술과 아이디어를 적극적으로 탐구하고, 끊임없이
                                    발전하여, <br />
                                    고객들의 요구사항과 기대를 초월하는 혁신적인 솔루션을 제공하고자 합니다.
                                    <br />
                                    <br />
                                    <br />
                                    <h5> 신뢰받는 기업이 되겠습니다.</h5>
                                    이것은 우리의 엄숙한 의무이며, 우리는 더 스마트하고 더 친밀해지도록 노력함으로써 현재의 브로넥스가 이루어낸 것보다 더 높은 유대관계를
                                    <br />
                                    만들어내고자 합니다.
                                    <br /> 기술과 서비스는 물론, 사람과 환경, 사회를 우선의 가치로 삼고 시대가 요구하는 회사로 거듭나겠습니다.
                                </p>
                                <div>
                                    <div className="greetingText_item">
                                        <div className="item_strong">브로넥스는</div>
                                        <div className="item_text">
                                            문화는 결과에 상관없이 모든 도전을 응원합니다. <br />
                                            우리는 혁신적인 기술과 도전적인 결과로 만들어진 최고의 제품을 통해 고객뿐만 아니라 브로넥스와 사업 관계를 맺고 있는 모든 사람들에게 깊은
                                            신뢰와 높은 이익을 제공하고자 합니다.
                                        </div>
                                    </div>
                                    <div className="greetingText_item">
                                        <div className="item_strong">브로넥스는</div>
                                        <div className="item_text">항상 고객 중심의 마음가짐으로 일하며, 고객들의 성공은 우리의 성공이라고 믿습니다.</div>
                                    </div>

                                    <p>
                                        고객을 위한 끊임없는 노력과 도전, <br />
                                        그리고 공정하고 투명한 경영 철학을 바탕으로 더욱 사랑받는 기업이 되고자 합니다.
                                        <br />
                                        <br />
                                        따뜻한 시선으로 지켜봐 주시기를 바랍니다. <br />
                                        감사합니다. <br />
                                    </p>
                                    <br />
                                </div>
                                <p style={{ textAlign: "right" }}> 주식회사 브로넥스 임직원 일동</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function DropDownMenu(props) {
    const { show, hide } = props;
    return (
        <>
            <DropDownSubMenu show={show} onMouseLeave={hide}>
                <div className="dropdown-box">
                    <DropMenuList show={show}>
                        <div>
                            <ul>
                                <li className="submenuTitle">
                                    <h3>Define Factory</h3>
                                </li>
                                <li>
                                    <Link to="/DF-Designing" className="menu_link_text">
                                        DF-Designing
                                    </Link>
                                </li>
                                <li className="submenuTitle" style={{ marginTop: 32 }}>
                                    <h3>Fulfillment</h3>
                                </li>
                                <li>
                                    <Link to="/suez" className="menu_link_text">
                                        Suez
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li className="submenuTitle">
                                    <h3>DF-Designing</h3>
                                </li>
                                <li>
                                    <Link to="/mes" className="menu_link_text">
                                        MES
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/analytics" className="menu_link_text">
                                        Analytics
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/qms" className="menu_link_text">
                                        QMS
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mc" className="menu_link_text">
                                        MC
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mb" className="menu_link_text">
                                        MB
                                    </Link>
                                </li>
                                <li className="submenuTitle" style={{ marginTop: 32 }}>
                                    <h3>Suez</h3>
                                </li>
                                <li>
                                    <Link to="/acs-solution" className="menu_link_text">
                                        ACS 솔루션
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/acs-simulation" className="menu_link_text">
                                        ACS 시뮬레이션
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <Link to="/notice" className="menu_link_text">
                                        공지사항
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/news" className="menu_link_text">
                                        언론보도
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <Link to="/company" className="menu_link_text">
                                        인사말
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about" className="menu_link_text">
                                        핵심가치
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/history" className="menu_link_text">
                                        회사연혁
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/address" className="menu_link_text">
                                        오시는길
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </DropMenuList>
                </div>
            </DropDownSubMenu>
        </>
    );
}

const DropDownSubMenu = styled.div`
    width: 100vw;
    padding: 30px 0;
    position: relative;
    border-top: 1px solid #ececec;
    box-shadow: 0 10px 12px rgba(0, 0, 0 / 0.8);

    pointer-events: ${(props) => (props.show ? "auto" : "none")};
    opacity: ${(props) => (props.show ? "1" : "0")};
    transition: ${(props) => (props.show ? "all 0.8s" : "")};
`;

const DropMenuList = styled.div`
    width: 780px;
    display: ${(props) => (props.show ? "table" : "none")};
    table-layout: fixed;

    div {
        display: table-cell;
        text-align: center;

        li {
            margin-bottom: 15px;
            text-align: left;
            font: normal normal bold 14px/16px Pretendard;
            letter-spacing: 0px;
            color: #343448;

            h3 {
                font-weight: 800;
            }
        }
    }
`;

import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/mb_back.png";

import "../../assets/css/contents/product.css";
import AskSection from "../../componenets/main/askSection";

export default function MbPage() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground
                title="MB"
                sub={hide}
                subTitle="DF-Designing"
                etc="Data Reporting & 실시간 모니터링, 의사결정 솔루션"
                background={background}
            />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            다양한 시각화하여 실시간모니터링을 통한 빠른 의사결정을 지원합니다.
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>
                            기업 리소스(설비,인력 등)와 작업 공정, 자재 현황, 물류 흐름 등 <br />
                            기업내 모든 정보를 이해하기 쉽게 보여주는 솔루션입니다.
                        </p>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout background-blue" style={{ marginTop: 80, marginBottom: 300 }}>
                <div className="contents_inner_container_layout" style={{ position: "relative" }}>
                    <div className="page_mainMB_cover">
                        <img src="/img/mb_main.png" alt="" />
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            데이터 시각화를 통한 신속한 의사결정 지원
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>각종 업무현황의 실시간 데이터 시각화 장보 모니터링을 통하여 합리적인 의사결정을 지원합니다.</p>
                    </div>
                    <div className="product_coverList_cont" style={{ paddingBottom: 50 }}>
                        <ul>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/data-analysis.png" alt="" />
                                </div>
                                <h4>시스템 현황 파악 용이</h4>
                                <p>
                                    실시간 현황 조회를 통한
                                    <br /> 시스템의 이상 및 현황 파악
                                </p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/diagram.png" alt="" />
                                </div>
                                <h4>시스템 분석 용이</h4>
                                <p>
                                    차트형태의 데이터 제공을 통해
                                    <br />
                                    전문지식이 없어도 시스템 분석 가능
                                </p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/flow-diagram.png" alt="" />
                                </div>
                                <h4>전문적인 데이터 분석</h4>
                                <p>
                                    차트형태의 데이터 제공을 통해
                                    <br />
                                    전문지식이 없어도 시스템 분석 가능
                                </p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/optimization.png" alt="" />
                                </div>
                                <h4>멀티 디바이스 지원</h4>
                                <p>
                                    장소에 구애받지않고
                                    <br />
                                    어디서든 확인 가능
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout background-reason">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title text_w">
                        <h3>도입효과</h3>
                        <p>
                            각종 KPI 정보의 상시적 가시화로 생산적인 기업활동을 유도함으로써
                            <br />
                            궁극적으로 사업 경쟁력을 강화할 수 있도록 지원합니다.
                        </p>
                    </div>
                    <div className="product_coverList_cont product_effect_cont text_w">
                        <ul>
                            <li>
                                <div className="product_effect_list">
                                    <p>
                                        생산현장의 종합적인 Real Time <br />
                                        관리 체계 강화
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_list">
                                    <p>
                                        본사중심의 전세계 공장
                                        <br />
                                        Visibility 확보
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_list">
                                    <p>
                                        주요핵심성과 지표의 기시화
                                        <br />
                                        확보로 신속한 의사결정지원
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/*  문의 하기 */}
            <AskSection />
        </>
    );
}

import React, { useState } from "react";

import "../../assets/css/contents/product.css";
import AskSection from "../../componenets/main/askSection";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";

import background from "../../assets/images/acs_back.png";

export default function AcsSolution() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground sub={hide} title="ACS 솔루션" subTitle="Suez" etc="Suez & ACS 솔루션" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>ACS 솔루션</h3>
                        <p>
                            ACS 란? <br />
                            상위통신 및 장비들과 통신하여 다수의 ACV에 명령을 전달하고 교통을 제어하는 System입니다.
                        </p>

                        <div className="page_sub_cover">
                            <img src="/img/Simulation_guidance_solution.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>운영 시물레이션의 새로운 패러다임을 선사합니다.</h3>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs01.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                누구나 쉽고 빠르게 <br />
                                정확한 지도 데이터를 생성합니다.
                            </h4>
                            <p>
                                경로를 다양하게 만들어 경로 최적화가 가능합니다.
                                <br />
                                자동으로 생성되는 관제/제어 데이터들은 실수가 없습니다.
                                <br />
                                소스코드 수정없이 거의 모든 세팅이 가능합니다.
                                <br />
                                비전문가도 쉽게 유지보수를 할 수 있어, 잦은 경로변경도 OK 입니다.
                            </p>
                        </div>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs02.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                운영 시뮬레이션을 통해
                                <br /> 잠재적인 문제를 100% 해결합니다.
                            </h4>
                            <p>
                                지도 데이터만 넣으면 운영 시뮬레이션 가능합니다.
                                <br />
                                자동으로 생성되는 리포트는 물동량 충족 및 실현성을 검증할 수 있습니다.
                                <br />
                                리포트를 통한 신뢰성 있는 시스템 제안은 수주확률을 높여줍니다.
                                <br />
                                시뮬레이션을 통한 최적화 작업은 운영효율을 40%까지 향상 시키며,
                                <br /> AGV 대수 최적화로 비용 절감까지 가능합니다.
                            </p>
                        </div>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs03.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                연결된 모든 장비의 정보를 <br />
                                신속, 정확하게 확인합니다.
                            </h4>
                            <p>
                                현장 시운전 전, 90% 이상의 시운전을 완료한다면 믿으시겠습니까?
                                <br />
                                잠재적인 문제 및 설계 에러를 미리 확인하고 해결 할 수 있습니다.
                                <br />
                                현장에 가지 않고도 정확한 테스트가 가능함으로
                                <br />
                                시운전 및 A/S 비용을 획기적으로 줄일 수 있습니다.
                            </p>
                        </div>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs04.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                다양한 형태의
                                <br /> AGV 주행제어 솔루션을 제공합니다.
                            </h4>
                            <p>
                                ACS와 GS를 함께 사용한다면, 시너지 효과가 극대화 됩니다.
                                <br />
                                ACS↔AGV 모두 검증되었음으로, 시운전이 필요 없습니다.
                                <br />
                                A/S 및 경로변경 비용이 무료 또는 최소화 됩니다.
                                <br />※ 많은 중복작업들이 사라짐으로 기본 ACS의 가격이 저렴합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout background-reason">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title text_w">
                        <h3>ACS 솔루션의 효과</h3>
                    </div>
                    <div className="effect-table text_w">
                        <ul>
                            <li className="effect-table-cell">
                                <h4>
                                    수주확률 <br /> <span>200% UP</span>
                                </h4>
                                <p>
                                    시뮬레이션을 통한 정확한
                                    <br />
                                    시스템 제안서 및 합리적인 견적서는 고객과의 신뢰를 구축 합니다.
                                </p>
                            </li>
                            <li className="effect-table-cell">
                                <h4>
                                    비용 <br /> <span>최소화</span>
                                </h4>
                                <p>
                                    ACS와 AGV는 중복작업이 많으나 Suez의 ACS를 이용하여 중복 작업 최소화로 <br /> 기본 ACS를 저렴하게 제공 가능합니다.
                                </p>
                            </li>
                            <li className="effect-table-cell">
                                <h4>
                                    현장시운전 기간
                                    <br /> <span>1/9 DOWN</span>
                                </h4>
                                <p>Suez의 ACS를 이용하여 문제 발생 시, 시뮬레이션 재현이 가능함으로 유지보수 및 A/S에도 큰 도움이 됩니다.</p>
                            </li>
                            <li className="effect-table-cell">
                                <h4>
                                    운영 효율 <br /> <span>40% UP</span>
                                </h4>
                                <p>다양한 운영기술으로 불합리한 운영을 최소화하여 운영 효율을 최대 40%까지 상승합니다.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/*  문의 하기 */}
            <AskSection />
        </>
    );
}

import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/mes_back.png";

import "../../assets/css/contents/product.css";
import AskSection from "../../componenets/main/askSection";

export default function Mes() {
    const [hide, setHide] = useState(false);
    const [tab, setTab] = useState(1);

    return (
        <>
            <PageHeaderBackground title="MES" sub={hide} subTitle="DF-Designing" etc="Data Reporting & Mes 솔루션" background={background} />
            <section className="contents_container_layout ">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">개요</h2>
                    <div className="mes_main_flex_cont">
                        <div className="mes_main_flex_inner">
                            <h3>
                                DFD 기반 브로넥스의 MES는 제조현장에 최적화된
                                <br /> 실시간 생산 모니터링 및 품질향상을 위한 웹기반 제조실행시스템입니다.
                            </h3>
                            <p>
                                MES는 제조기업의 기업정보 시스템에서 로우엔드 생산현장의 데이타와 하이엔드 경영정보 데이타를 연결시켜주는 중요한 역할을 합니다.
                                스마트공장은 Industry4.0 시대의 이상적인 제조환경으로 정의될 수 있는데 MES는 스마트공장의 중심에 있는 시스템이라고 할 수
                                있습니다.
                                <br />
                                <br />
                                DFD 기반의 MES 시스템은 생산계획의 수립, 작업지시 및 공정관리, POP 연계와 같은 MES의 기본 기능 뿐 아니라 재고관리, 수주관리와
                                같은 관련모듈과 직접 연계될 수 있도록 개발되었습니다.
                                <br />
                                <br />
                                또한 바코드 시스템과 같은 출력 시스템, PDA, 스마트폰, 테블릿PC와도 연계하여 이동이 빈번한 생산현장에서 편리하게 사용할 수 있도록
                                하였습니다.
                            </p>
                        </div>
                        <div className="mes_main_flex_inner">
                            <div className="main_flex_cover">
                                <img src="/img/mes_banner.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">시스템 구성도</h2>
                    <div className="df_case_cont_tab mes_position_tab">
                        <ul>
                            <li onClick={() => setTab(1)} className={tab === 1 ? "tabActive" : ""}>
                                <div>
                                    <span>H/W 구성도</span>
                                </div>
                            </li>
                            <li onClick={() => setTab(2)} className={tab === 2 ? "tabActive" : ""}>
                                <div>
                                    <span>N/W 구성도</span>
                                </div>
                            </li>
                            <li onClick={() => setTab(3)} className={tab === 3 ? "tabActive" : ""}>
                                <div>
                                    <span>업무 흐름도</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {tab === 1 && (
                        <div className="page_main_cont">
                            <p>
                                ERP, POP 등 타 정보시스템과 연계한 데이터를 기반으로 자재의 입출고현황, 생산 공정, 포장, 창고 등 물류 흐름 데이터를 실시간으로
                                수집하고 분석합니다.
                            </p>
                            <div className="page_main_cover">
                                <img src="/img/mes_positon1.png" alt="" />
                            </div>
                            <div className="mes_positionPoint_list">
                                <ul>
                                    <li>자재 바코드 라벨 발행 및 부착 후 창고입고</li>
                                    <li>바코드 추적을 통한 체계적인 자재투입-작업지시-생산실적-WIP 관리</li>
                                    <li>포장 시 바코드 리딩으로 포장 실적 및 출고</li>
                                    <li>출하요청 시 바코드 리딩으로 제품 출고 및 실적 정보 집계</li>
                                </ul>
                            </div>
                        </div>
                    )}
                    {tab === 2 && (
                        <div className="page_main_cont">
                            <p>인터넷을 통한 웹 기반으로 각 공정별 네트워크를 구성하여 실시간으로 데이터를 전송합니다.</p>
                            <div className="page_main_cover">
                                <img src="/img/mes_positon2.png" alt="" style={{ width: "90%" }} />
                            </div>
                        </div>
                    )}
                    {tab === 3 && (
                        <div className="page_main_cont">
                            <p>자재 입고부터 제품 출하 과정까지 ERP 시스템과 연동하여 생산계획과 실적, 생산 현황을 실시간으로 통제할 수 있습니다.</p>
                            <div className="page_main_cover">
                                <img src="/img/mes_main3.png" alt="" style={{ width: "80%" }} />
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">
                        주요 기능 <span>* 본 기능은 고객의 요구사항에 따라 변경 가능합니다.</span>
                    </h2>
                    <div className="mes_point_cont">
                        <div className="mes_point_row">
                            <div className="mes_point_item">
                                <div>기준정보관리</div>
                                <ul>
                                    <li>공통코드관리</li>
                                    <li>회사정보관리</li>
                                    <li>품목관리</li>
                                    <li>거래처관리</li>
                                    <li>창고관리</li>
                                </ul>
                            </div>
                            <div className="mes_point_item">
                                <div>구매관리</div>
                                <ul>
                                    <li>발주관리</li>
                                    <li>입고관리</li>
                                    <li>불출관리</li>
                                    <li>매입현황</li>
                                    <li>수불관리</li>
                                    <li>반품관리</li>
                                </ul>
                            </div>
                            <div className="mes_point_item">
                                <div>영업관리</div>
                                <ul>
                                    <li>견적관리</li>
                                    <li>수주관리</li>
                                    <li>판매관리</li>
                                    <li>매출마감</li>
                                    <li>반품관리</li>
                                </ul>
                            </div>
                            <div className="mes_point_item">
                                <div>생산관리</div>
                                <ul>
                                    <li>생산계획관리</li>
                                    <li>생산지시관리</li>
                                    <li>생산실적관리</li>
                                    <li>생산이력관리</li>
                                    <li>계획대비실적현황</li>
                                    <li>라벨관리</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mes_point_row">
                            <div className="mes_point_item">
                                <div>자재관리</div>
                                <ul>
                                    <li>재고현황</li>
                                    <li>원자재불출관리</li>
                                    <li>재고이동관리</li>
                                    <li>창고별재고관리</li>
                                </ul>
                            </div>

                            <div className="mes_point_item">
                                <div>설비관리</div>
                                <ul>
                                    <li>설비관리</li>
                                    <li>일상점검관리</li>
                                    <li>정기점검관리</li>
                                    <li>보전이력관리</li>
                                    <li>검교정관리</li>
                                </ul>
                            </div>
                            <div className="mes_point_item">
                                <div>품질관리</div>
                                <ul>
                                    <li>검사항목관리</li>
                                    <li>수입검사</li>
                                    <li>공정검사</li>
                                    <li>출하검사</li>
                                </ul>
                            </div>

                            <div className="mes_point_item">
                                <div>개발관리</div>
                                <ul>
                                    <li>BOM관리</li>
                                    <li>BOR관리</li>
                                    <li>문서관리</li>
                                    <li>설계변경이력관리</li>
                                    <li>일정관리</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">주요 특징</h2>
                    <div className="mes_keyPoint">
                        <ul>
                            <li>
                                <div>
                                    <h4>웹 기반 인프라 구축</h4>
                                    <p>100% 웹기반으로 PC, 스마트폰 등 멀티 디바이스 접속 환경에 최적화된 환경 제공</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <h4>확장성을 고려한 유연한 설계 및 구축</h4>
                                    <p>SCHI, ERP, HMI 등 타 정보시스템과 연계가 용이하고, 공정 라인, 설비 증설 등 기업 요구와 사업 확장에 대비한 시스템 설계</p>
                                </div>
                            </li>
                            <li>
                                <h4>제조분야 특성에 최적화된 MES 개발</h4>
                                <p>
                                    자동차 부품, 전기전자 부품, 식품 가공, 의료기기 등 각 제조 현장에 맞는 맞춤 설계 및 제조 업무별 모듈 단위로 세분화된 기능을
                                    조합하여 완성
                                </p>
                            </li>
                            <li>
                                <h4>H/W 장비를 활용한 업무 혁신</h4>
                                <p>바코드, Tablet, PDA, RFID, PLC 등 데이터 입력 장치를 활용한 정확한 정보 수집, 분석, 활용, 가공</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">MES 화면</h2>
                    <div className="analtyics_layout_cont">
                        <div className="analtyics_layout_item">
                            <div>
                                <img src="/img/qms_ex1.png" alt="" style={{ height: 314 }} />
                                {/* <p>수입검사 등록</p> */}
                            </div>
                            <div>
                                <img src="/img/qms_ex2.jpg" alt="" />
                                {/* <p>공정검사 등록</p> */}
                            </div>
                        </div>
                        <div className="analtyics_layout_item">
                            <div>
                                <img src="/img/qms_ex3.jpg" alt="" />
                                {/* <p>부적합관리</p> */}
                            </div>
                            <div>
                                <img src="/img/qms_ex4.jpg" alt="" />
                                {/* <p>통계</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">도입효과</h2>

                    <div className="product_coverList_cont product_effect_cont" style={{ paddingTop: 0 }}>
                        {/* <div className="product_effect_chartCover">
                            <img src="/img/mesEffect_chart.png" alt="" />
                        </div> */}
                        <ul>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart4.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>25%</h3> */}
                                    <p>
                                        실시간 품질정보 수집 및 분석관리로
                                        <br />
                                        <strong>공정불량률 25% 개선</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart3.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>9%</h3> */}
                                    <p>
                                        신속하고 일관성 있는 생산실적관리로
                                        <br />
                                        <strong>생산시간 40% 단축</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart6.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>20%</h3> */}
                                    <p>
                                        정확한 생산 데이터 수집 및 관리로
                                        <br />
                                        <strong>재공재고 20% 개선</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart7.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>18%</h3> */}
                                    <p>
                                        설비 가동상태 실시간 모니터링 및 관리로
                                        <br />
                                        <strong>설비 강동률 18% 증가</strong>
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart2.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>25%</h3> */}
                                    <p>
                                        실시간 생산정보 확인으로
                                        <br />
                                        <strong>업무지연 27% 감소</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart9.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>9%</h3> */}
                                    <p>
                                        정보 인프라 구축으로 인한
                                        <br />
                                        <strong>제품품질 32% 향상</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart9.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>20%</h3> */}
                                    <p>
                                        공정별 소요되는 체류시간 감소로
                                        <br />
                                        <strong>리드타임 32% 향상</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart8.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>18%</h3> */}
                                    <p>
                                        간편한 정보입력으로 인한
                                        <br />
                                        <strong>문서작업시간 60% 단축</strong>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">도입 방법</h2>
                    <div className="product_type_cont">
                        <table>
                            <colgroup>
                                <col width="20%" />
                                <col width="40%" />
                                <col width="40%" />
                            </colgroup>
                            <thead>
                                <tr className="type_table_header">
                                    <th></th>
                                    <th>구축형</th>
                                    <th>클라우드 서비스</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="type_table_body">
                                    <th>대상기업</th>
                                    <td>
                                        IT 전담 조직이 있는 기업 <br />
                                        기업 업무에 보다 최적화된 시스템을 도입하고 싶은 기업
                                    </td>
                                    <td>
                                        IT 전담 조직이 없어 운영하기 어려운 기업 <br />
                                        초기 구축비가 부담이 되는 기업
                                    </td>
                                </tr>
                                <tr className="type_table_body">
                                    <th>필요사항</th>
                                    <td>H/W, S/W, N/W 구축 필요</td>
                                    <td>협의</td>
                                </tr>
                                <tr className="type_table_body">
                                    <th>Pricing</th>
                                    <td>
                                        SW 라이선스 + 서비스(구축) + SW 유지보수
                                        <br />
                                        <span>* SW라이선스 및 서비스는 1회, 유지보수는 연간 청구</span>
                                    </td>
                                    <td>
                                        구독료(월과금, 연간 청구) <br />
                                        <span>* 표준 프로세스 컨설팅, ERP 및 설비 I/F 별도 (1회)</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            {/*  문의 하기 */}
            <AskSection />
        </>
    );
}
